<template>
  <v-form ref="formAssignments" v-on:submit.prevent="saveForm">
    <div class="row mt-4 pt-3">
      <div v-if="errorMessage" class="col-lg-12 col-md-12 col-sm-12 pb-1 pt-0">
        <div class="alert alert-danger py-2" role="alert">
          <small>{{ errorMessage }}</small>
        </div>
      </div>

      <div class="col-lg-12 col-md-12 col-sm-12 pb-2 pt-0">
        <v-text-field
          label="Fecha asignación"
          type="date"
          :rounded="rounded"
          :color="color"
          :dark="dark"
          :background-color="bgColor"
          :rules="required"
          :disabled="true"
          :hide-details="details"
          :outlined="outlined"
          :filled="filled"
          :dense="dense"
          :autocomplete="autocomplete"
          v-model="assignment.date"
        >
        </v-text-field>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 pb-2 pt-0">
        <v-autocomplete
          label="Usuario"
          :rounded="rounded"
          :color="color"
          :dark="dark"
          :background-color="bgColor"
          :rules="required"
          :disabled="disabled"
          :hide-details="details"
          :outlined="outlined"
          :filled="filled"
          :dense="dense"
          :autocomplete="autocomplete"
          :items="users"
          item-text="CORREO"
          :multiple="false"
          return-object
          small-chips
          v-model="assignment.user"
        >
        </v-autocomplete>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 pb-2 pt-0">
        <v-autocomplete
          label="Boletería"
          :rounded="rounded"
          :color="color"
          :dark="dark"
          :background-color="bgColor"
          :rules="required"
          :disabled="disabled"
          :hide-details="details"
          :outlined="outlined"
          :filled="filled"
          :dense="dense"
          :autocomplete="autocomplete"
          :items="boxOffices"
          item-text="DESCRIPCION"
          :multiple="false"
          return-object
          small-chips
          v-model="assignment.boxOffice"
        >
        </v-autocomplete>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 pb-4 pt-4 d-flex">
        <v-btn
          type="submit"
          :loading="loading"
          :disabled="loading"
          tile
          dark
          color="primary"
          depressed
          class="ml-auto"
        >
          Guardar
        </v-btn>
      </div>
    </div>
  </v-form>
</template>

<script>
import config from "./baseConfig";
import moment from "moment-timezone";
import axios from "@/api/entradaslima";
import store from "../../store";
export default {
  props: {
    pointsSale: Array
  },

  data() {
    return {
      ...config,
      //
      upToZero: [v => (!!v && v > 0) || "Incorrecto"],
      //
      errorMessage: "",
      loading: false,
      //
      users: [],
      boxOffices: [],
      assignment: {
        user: null,
        boxOffice: null,
        date: moment()
          .tz("America/Lima")
          .format("YYYY-MM-DD")
      },
      idParque: store.getters.getSessionParqueId
    };
  },
  async mounted() {
    await this.getUsersBoleteria();
    await this.getBoxOffices();
  },
  methods: {
    async getUsersBoleteria() {
      const { data, status } = await axios.get(
        `/parques/${this.idParque}/usuarios/perfil/-6`
      );
      if (status != 200) return;
      this.users = data.data;
    },
    async getBoxOffices() {
      const { data, status } = await axios.get(
        `/parques/${this.idParque}/boleterias`
      );
      if (status != 200) return;
      this.boxOffices = data.data;
    },
    validateForm() {
      return this.$refs.formAssignments.validate();
    },
    saveForm() {
      if (!this.validateForm()) return;
      const assignmentDTO = { ...this.assignment };
      this.saveAssignment(assignmentDTO);
    },
    async saveAssignment(assignmentDTO) {
      this.$emit("save", assignmentDTO);
    }
  }
};
</script>

<style lang="scss" scoped></style>
