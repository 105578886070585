<template>
  <v-dialog
    scrollable
    :width="width ? width : '800'"
    :persistent="persistent ? persistent : true"
    v-model="dialog"
  >
    <v-card class="bg-dark" elevation="0">
      <v-toolbar dense flat dark height="65px" color="#1b2431">
        <v-toolbar-title>
          <div class="d-flex py-3 flex-column">
            <h3 class="title-dialog mt-2 mb-0">{{ title }}</h3>
            <p class="mb-0 subtitle-dialog">
              <small class="">{{ subtitle }}</small>
            </p>
          </div>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon small dark @click="closeDialog">
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="scrolled scroll-type-2 bg-dark" style="max-height: 80vh">
        <slot name="form-dialog"> </slot>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    width: String,
    dialog: Boolean,
    persistent: Boolean,
    title: String,
    subtitle: String,
    closeDialog: Function,
  },
};
</script>

<style lang="scss" scoped></style>
