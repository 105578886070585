<template>
  <v-data-table
    :headers="headers"
    :items="assignments"
    :items-per-page="10"
    class="elevation-0 projects-table"
  >
    <template v-slot:[`item.DESCRIPCION_ESTADO`]="{ item }">
      <v-chip
        class="mb-0"
        small
        :color="item.ESTADO == '1' ? 'green' : 'red'"
        >{{ item.DESCRIPCION_ESTADO }}</v-chip
      >
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <div class="d-flex justify-content-end">
        <v-btn
          v-if="item.ESTADO === 1"
          :loading="item.IDASIGNACION === loadingReport"
          class="action-btn"
          title="Liquidación de Caja"
          @click="$emit('openReportBox', { ...item })"
          small
          icon
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M3 6V18H13.32C13.1 17.33 13 16.66 13 16H7C7 14.9 6.11 14 5 14V10C6.11 10 7 9.11 7 8H17C17 9.11 17.9 10 19 10V10.06C19.67 10.06 20.34 10.18 21 10.4V6H3M12 9C10.3 9.03 9 10.3 9 12C9 13.7 10.3 14.94 12 15C12.38 15 12.77 14.92 13.14 14.77C13.41 13.67 13.86 12.63 14.97 11.61C14.85 10.28 13.59 8.97 12 9M21.63 12.27L17.76 16.17L16.41 14.8L15 16.22L17.75 19L23.03 13.68L21.63 12.27Z"
            />
          </svg>
        </v-btn>
        <CrudButtons
          :noEdit="true"
          :noDelete="item.ESTADO === 0 || isToday === false"
          :loadingDelete="item.IDASIGNACION === loadingDelete"
          @remove="$emit('openDelete', item)"
        ></CrudButtons>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import CrudButtons from "@/components/buttons/CrudButtons";
export default {
  components: {
    CrudButtons
  },
  props: {
    loadingReport: Number,
    loadingDelete: Number,
    assignments: Array,
    isToday: Boolean
  },
  data() {
    return {
      headers: [
        {
          text: "Id",
          align: "start",
          sortable: false,
          value: "IDASIGNACION"
        },
        {
          text: "Boletería",
          sortable: false,
          value: "DESCRIPCION" // cambiar value
        },
        {
          text: "Usuario",
          align: "start",
          sortable: false,
          value: "CORREO" // cambiar value
        },
        {
          text: "Token",
          sortable: false,
          value: "TOKEN" // cambiar value
        },
        {
          text: "Fecha de Asignación",
          sortable: false,
          value: "FECHAASIGNACION" // cambiar value
        },
        {
          text: "Estado",
          sortable: false,
          value: "DESCRIPCION_ESTADO" // cambiar value
        },
        {
          text: "",
          sortable: false,
          value: "actions"
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped></style>
