<template>
  <div class="projects mb-4">
    <header class="projects-header">
      <TitleSection
        :title="'Asignación de Boleterías'"
        :subtitle="'Gestiona las asignaciones de las boleterias en el sistema'"
      />
    </header>
    <div class="row py-4">
      <div class="col-lg-3 col-md-3 col-sm-12 pb-2 pt-0" style="display:none">
        <v-autocomplete
          label="Boletería"
          :rounded="rounded"
          :color="color"
          :dark="dark"
          :background-color="bgColor"
          :rules="required"
          :disabled="disabled"
          :hide-details="details"
          :outlined="outlined"
          :filled="filled"
          :dense="dense"
          :autocomplete="autocomplete"
          single-line
          :items="boxOffices"
          item-text="DESCRIPCION"
          :multiple="false"
          return-object
          small-chips
          v-model="assignment.boxOffice"
        >
        </v-autocomplete>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-12 pb-2 pt-0">
        <v-text-field
          label="Fecha fin de asignación"
          type="date"
          :rounded="rounded"
          :color="color"
          :dark="dark"
          :background-color="bgColor"
          :rules="required"
          :disabled="disabled"
          :hide-details="details"
          :outlined="outlined"
          :filled="filled"
          :dense="dense"
          :autocomplete="autocomplete"
          single-line
          v-model="assignment.date"
        >
        </v-text-field>
      </div>
      <div class="col-lg-1 col-md-3 col-sm-12 pb-2 pt-0">
        <v-btn
          type="submit"
          :loading="loading"
          :disabled="loading"
          tile
          dark
          color="primary"
          depressed
          class="h-100"
          @click="getAssignment"
          ><v-icon left dark> mdi-magnify </v-icon>
          Buscar
        </v-btn>
      </div>
      <div class="col-lg-2 col-md-3 col-sm-12 pb-2 pt-0">
        <v-btn
          v-if="assignment.date === today"
          :class="'ml-5'"
          @click="openDialog"
          class="zmdi action-btn"
          title="Agregar nueva asignación"
          icon
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" />
          </svg>
        </v-btn>
        <v-btn
          :class="'ml-5'"
          :loading="loadingDayReport"
          @click="openReportDay"
          class="zmdi action-btn"
          title="Reporte Diario de Venta"
          icon
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path
              d="M3 6V18H13.32C13.1 17.33 13 16.66 13 16H7C7 14.9 6.11 14 5 14V10C6.11 10 7 9.11 7 8H17C17 9.11 17.9 10 19 10V10.06C19.67 10.06 20.34 10.18 21 10.4V6H3M12 9C10.3 9.03 9 10.3 9 12C9 13.7 10.3 14.94 12 15C12.38 15 12.77 14.92 13.14 14.77C13.41 13.67 13.86 12.63 14.97 11.61C14.85 10.28 13.59 8.97 12 9M21.63 12.27L17.76 16.17L16.41 14.8L15 16.22L17.75 19L23.03 13.68L21.63 12.27Z"
            />
          </svg>
        </v-btn>
      </div>
    </div>
    <TableAssignments
      :loadingDelete="loadingDelete"
      :loadingReport="loadingReport"
      :assignments="assignments"
      :isToday="assignment.date === today"
      @openDelete="removeAssignment"
      @openReportBox="openReportAssignment"
    >
    </TableAssignments>

    <!-- DIALOG FORM -->
    <WDialog
      :dialog="dialog"
      :closeDialog="closeDialog"
      :title="getTitle"
      width="450"
    >
      <template v-slot:form-dialog>
        <FormAssignments v-if="dialog" :users="users" @save="saveAssignment" />
      </template>
    </WDialog>
  </div>
</template>

<script>
import TitleSection from "@/components/titles/TitleSection";
import TableAssignments from "@/components/tables/TableAssignments";
import FormAssignments from "@/components/forms/FormAssignments";
import WDialog from "@/components/dialogs/WindowDialog";
import { mapActions } from "vuex";
import Swal from "sweetalert2";
import moment from "moment-timezone";
import axios from "@/api/entradaslima";
import store from "../../store";
import { b64toBlob } from "../../utils/file";
export default {
  components: {
    TitleSection,
    TableAssignments,
    WDialog,
    FormAssignments
  },
  data() {
    return {
      rounded: false,
      color: "#738297",
      bgColor: "#ffffff",
      required: [v => !!v || "Requerido"],
      upToZero: [v => (!!v && v > 0) || "Incorrecto"],
      disabled: false,
      details: true,
      filled: true,
      outlined: false,
      dense: true,
      autocomplete: "off",
      readonly: true,
      dark: false,
      dialog: false,
      loading: false,
      loadingDelete: -1,
      loadingReport: -1,
      loadingDayReport: false,
      assignment: {
        boxOffice: {},
        date: moment()
          .tz("America/Lima")
          .format("YYYY-MM-DD")
      },
      idParque: store.getters.getSessionParqueId,
      usuario: store.getters.getSessionUser.CORREO,
      assignments: [],
      boxOffices: [],
      users: [],
      today: moment()
        .tz("America/Lima")
        .format("YYYY-MM-DD")
    };
  },
  computed: {
    getTitle() {
      return "Nueva Asignación";
    },
    getSubtitle() {
      return "Rellena todos los campos para crear una nueva asignación";
    }
  },
  async mounted() {
    await this.getBoxOffices();
    await this.getAssignment();
  },
  methods: {
    ...mapActions({
      generateConfirmDialog: "generateConfirmDialog",
      generateSnackBarMessage: "generateSnackBarMessage"
    }),
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    async getBoxOffices() {
      try {
        const { data, status } = await axios.get(
          `/parques/${this.idParque}/boleterias`
        );
        if (status != 200) return;
        this.boxOffices = data.data;
      } catch (error) {
        return;
      }
    },
    async getAssignment() {
      try {
        this.loading = true;
        const { data } = await axios.get(
          `/parques/${this.idParque}/boleterias/${this.assignment.boxOffice
            .IDBOLETERIA || "0"}/asignaciones?fecha=${this.assignment.date}`
        );
        this.loading = false;
        this.assignments = data.data;
      } catch (error) {
        this.loading = false;
        return Swal.fire({
          icon: "error",
          text: error.response.data.message || "Ocurrió un error interno"
        });
      }
    },
    async saveAssignment(dto) {
      try {
        const { data } = await axios.post(
          `/parques/${this.idParque}/boleterias/${dto.boxOffice.IDBOLETERIA}/asignaciones`,
          {
            idUsuario: dto.user.IDUSUARIO,
            usuario: this.usuario
          }
        );
        this.closeDialog();
        this.generateSnackBarMessage({
          message: data.message
        });
        await this.getAssignment();
      } catch (error) {
        return Swal.fire({
          icon: "error",
          text: error.response.data.message || "Ocurrió un error interno"
        });
      }
    },
    async removeAssignment(dto) {
      const response = await this.generateConfirmDialog({
        message: "¿Esta seguro de eliminar la asignación?",
        confirmMessage: "Si, quiero eliminar"
      });
      if (response) {
        try {
          this.loadingDelete = dto.IDASIGNACION;
          const { data } = await axios.delete(
            `/parques/${this.idParque}/boleterias/${dto.IDBOLETERIA}/asignaciones/${dto.IDASIGNACION}`,
            {
              data: {
                usuario: this.usuario
              }
            }
          );
          this.loadingDelete = -1;
          this.generateSnackBarMessage({
            message: data.message
          });
          await this.getAssignment();
        } catch (error) {
          this.loadingDelete = -1;
          return Swal.fire({
            icon: "error",
            text: error.response.data.message || "Ocurrió un error interno"
          });
        }
      }
    },
    async openReportDay() {
      try {
        this.loadingDayReport = true;
        const { data } = await axios.post(
          `/parques/${this.idParque}/ventasdiarias/${this.assignment.date}/file`
        );
        this.loadingDayReport = false;

        if (!data.data) return false;

        const { content, mimetype, extension } = data.data;

        // Genera un blob
        const blob = b64toBlob(content, mimetype);
        const blobUrl = URL.createObjectURL(blob);

        // Descarga el pdf
        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = `ventadiaria_${this.assignment.date}.${extension}`;
        document.body.appendChild(link);
        link.click();
        setTimeout(() => {
          link.remove();
        }, 4000);
      } catch (error) {
        this.loadingDayReport = false;
        return Swal.fire({
          icon: "error",
          text: error.response.data.message || "Ocurrió un error interno"
        });
      }
    },
    async openReportAssignment(dto) {
      try {
        this.loadingReport = dto.IDASIGNACION;
        const { data } = await axios.post(
          `/parques/${this.idParque}/asignaciones/${dto.IDASIGNACION}/file`
        );
        this.loadingReport = -1;

        if (!data.data) return false;

        const { content, mimetype, extension } = data.data;

        // Genera un blob
        const blob = b64toBlob(content, mimetype);
        const blobUrl = URL.createObjectURL(blob);

        // Descarga el pdf
        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = `asignacion_${dto.IDASIGNACION}.${extension}`;
        document.body.appendChild(link);
        link.click();
        setTimeout(() => {
          link.remove();
        }, 4000);
      } catch (error) {
        this.loadingReport = -1;
        return Swal.fire({
          icon: "error",
          text: error.response.data.message || "Ocurrió un error interno"
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
